
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Error from '@/components/Error.vue'
import Icon from '@/components/Icon.vue'
import Card from '@/components/lor/Card.vue'

@Options({components: {Loading, Icon, Error, Card}})
export default class LolLeaderboards extends Vue {

    leaderboardRegions: Array<any> = [
        {
            title: 'Americas',
            value: 'americas',
            to: 'americas'
        },
        {
            title: 'Europe',
            value: 'europe',
            to: 'europe'
        },
        {
            title: 'Asia',
            value: 'sea',
            to: 'sea'
        }
    ]

    loading = true
    error = false
    errorDetail = ""
    maxIndex = 100
    players: Array<any> = []
    region = ""
    sideAdMarginTop = 20

    mounted (): void {
        window.addEventListener('scroll', this.$generic.throttle(this.scrollHandler, 200))
    }

    beforeDestroy (): void {
        window.removeEventListener('scroll', this.$generic.throttle(this.scrollHandler, 200))
    }

    async created(): Promise<void> {
        this.region = this.$route.params.region.toString()
        if (this.region == "asia" || this.region == "apac")
            this.$router.push('sea')
        if (this.$route.query.rendertron)
            this.maxIndex = 200
        this.$stge.loc['lor:leaderboardRegion'] = this.region
        this.leaderboardRegions = this.liftSelected(this.leaderboardRegions, this.region, (item: any) => item.value)
        try {
            this.players = await this.$api.get('lor', ['leaderboards', this.region])
        } catch (e: any) {
            this.error = true
            this.errorDetail = e.response.data.detail
        }
        this.loading = false
        this.$meta.use({
            title: `Master Leaderboard - ${this.$css.capFirst(this.region)} | StatHub.gg LoR`,
            og: {
                image: '/img/icons/android-chrome-maskable-192x192.png',
                description: (() => {
                    let str = "Top 10 Masters: "
                    for (const player of this.players.slice(0, 10)) {
                        str += `${player.rank + 1}. ${player.name} (${player.lp}LP) | `
                    }
                    return str
                })()
            }
        })
    }

    liftSelected <T>(arr: Array<T>, val: string, key: CallableFunction): Array<T> {
        let o: Array<T> = []
        for (const item of arr) {
            if (key(item) == val)
                o.unshift(item)
            else
                o.push(item)
        }
        return o
    }

    scrollHandler(): void {
        let sideColumnHeight = (this.$refs.sideColumn as HTMLElement)?.offsetHeight
        this.sideAdMarginTop = Math.max(window.pageYOffset - sideColumnHeight, 20)
    }

}
