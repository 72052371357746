<template>
    <Loading v-if="loading">LOADING</Loading>
    <Error v-else-if="error">{{errorDetail}}</Error>
    <div class="py-3" v-else>
        <div class="row mx-1 mx-lg-4 mx-xl-5">
            <div class="col-12 col-md-4 pe-md-4 text-left">
                <div ref="sideColumn">
                    <h3 class="text-truncate text-left mx-1">Master Leaderboard</h3>
                    <h6 class="mt-3 mx-1">Region: <span class="capfirst">{{region}}</span></h6>
                    <hr>
                    <div class="mt-3 board-nav-container">
                        <router-link
                            v-for="item in leaderboardRegions"
                            :key="'lor_leaderboard_' + item.value"
                            :class="[item.to == region ? 'region-btn-active': 'region-btn', 'w-100 d-block px-3 py-2 d-flex border-bottom']"
                            :to="item.to"
                        >
                            <div>
                                {{item.title}}
                            </div>
                            <div class="ms-auto">
                                <Icon name="chevron-right" />
                            </div>
                        </router-link>
                    </div>
                </div>
                <VenatusAd class="mb-2 side-ad-scroll-transition" :style="{marginTop: sideAdMarginTop + 'px'}" v-if="$store.state.winWidth > 768" :height="1000" />
                <VenatusAd class="mt-2" v-else :height="100" />
            </div>
            <div class="col-12 col-md-8 mt-1 mt-md-0 ps-md-4 text-left">
                <div class="pt-2 px-2 d-flex text-muted justify-content-between small">
                    <div>Rank #</div>
                    <div>Player Name</div>
                    <div v-if="$store.state.winWidth >= 576">Wr (20 Games) / League Points</div>
                    <div v-else>Wr (20 Games) / LP</div>
                </div>
                <hr>
                <router-link
                    v-for="player in players.slice(0, maxIndex)"
                    :key="'leaderboard_player_' + region + player.rank"
                    :class="[player.rank < 10 ? 'top-bg': 'win-bg', 'mt-2 pt-2 pb-1 py-md-3 px-3 d-flex justify-content-between align-items-center']"
                    :to="{name: 'LorProfile', params: {name: player.name, tag: $asset.lor.pogTagByRegion[region]}}"
                >
                    <div>
                        <h5 class="my-auto" v-if="$store.state.winWidth >= 576">{{player.rank + 1}}</h5>
                        <h6 class="my-auto" v-else>{{player.rank + 1}}</h6>
                    </div>
                    <div class="ps-3 ps-lg-5" style="width: 70%">
                        <Card
                            :code="player.profile_icon || '03MT039'"
                            variant="avatar"
                            :height="$store.state.winWidth < 992 ? 36: 50"
                            :width="$store.state.winWidth < 992 ? 36: 50"
                            class="me-3"
                        />
                        <span v-if="$store.state.winWidth >= 576">{{player.name}}</span>
                        <small v-else>{{player.name}}</small>
                    </div>
                    <div :class="['text-warning text-nowrap', $store.state.winWidth < 576 ? 'small': '']">
                        <span class="text-info">{{$math.round(player.win_rate * 100, 100) || '( ? ) '}}%</span>
                        <br v-if="$store.state.winWidth < 576">
                        <span v-if="$store.state.winWidth >= 576" class="mx-sm-2"> / </span>
                        {{player.lp}} LP
                    </div>
                </router-link>
                <div v-if="players.length < 50" class="null-bg mt-2 py-3 text-center">
                    <Error :inline="true">Ranks were recently reset, may need a few days to fill back up</Error>
                </div>
                <div v-if="maxIndex < players.length" class="null-bg mt-2 py-3 cursor-pointer text-center" @click="maxIndex = maxIndex + 100">
                    Show More
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
@import "@/scss/transitions.scss";

.board-nav-container {
    overflow-y: scroll;
}

@include media-breakpoints-down($md) {
    .board-nav-container {
        height: 130px;
        overflow-y: scroll;
    }
}
@include variable-transition("side-ad-scroll-transition", 'margin-top', 0.2s);

.region-btn {
    background: linear-gradient(to right, rgba(31, 188, 255, 0.326) 0%, rgba(31, 188, 255, 0.19) 50%, rgba(31, 188, 255, 0.05) 100%);
}

.region-btn-active {
    background: linear-gradient(to right, rgba(31, 255, 95, 0.326) 0%, rgba(31, 255, 95, 0.19) 50%, rgba(31, 255, 95, 0.05) 100%);
}

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Loading from '@/components/Loading.vue'
import Error from '@/components/Error.vue'
import Icon from '@/components/Icon.vue'
import Card from '@/components/lor/Card.vue'

@Options({components: {Loading, Icon, Error, Card}})
export default class LolLeaderboards extends Vue {

    leaderboardRegions: Array<any> = [
        {
            title: 'Americas',
            value: 'americas',
            to: 'americas'
        },
        {
            title: 'Europe',
            value: 'europe',
            to: 'europe'
        },
        {
            title: 'Asia',
            value: 'sea',
            to: 'sea'
        }
    ]

    loading = true
    error = false
    errorDetail = ""
    maxIndex = 100
    players: Array<any> = []
    region = ""
    sideAdMarginTop = 20

    mounted (): void {
        window.addEventListener('scroll', this.$generic.throttle(this.scrollHandler, 200))
    }

    beforeDestroy (): void {
        window.removeEventListener('scroll', this.$generic.throttle(this.scrollHandler, 200))
    }

    async created(): Promise<void> {
        this.region = this.$route.params.region.toString()
        if (this.region == "asia" || this.region == "apac")
            this.$router.push('sea')
        if (this.$route.query.rendertron)
            this.maxIndex = 200
        this.$stge.loc['lor:leaderboardRegion'] = this.region
        this.leaderboardRegions = this.liftSelected(this.leaderboardRegions, this.region, (item: any) => item.value)
        try {
            this.players = await this.$api.get('lor', ['leaderboards', this.region])
        } catch (e: any) {
            this.error = true
            this.errorDetail = e.response.data.detail
        }
        this.loading = false
        this.$meta.use({
            title: `Master Leaderboard - ${this.$css.capFirst(this.region)} | StatHub.gg LoR`,
            og: {
                image: '/img/icons/android-chrome-maskable-192x192.png',
                description: (() => {
                    let str = "Top 10 Masters: "
                    for (const player of this.players.slice(0, 10)) {
                        str += `${player.rank + 1}. ${player.name} (${player.lp}LP) | `
                    }
                    return str
                })()
            }
        })
    }

    liftSelected <T>(arr: Array<T>, val: string, key: CallableFunction): Array<T> {
        let o: Array<T> = []
        for (const item of arr) {
            if (key(item) == val)
                o.unshift(item)
            else
                o.push(item)
        }
        return o
    }

    scrollHandler(): void {
        let sideColumnHeight = (this.$refs.sideColumn as HTMLElement)?.offsetHeight
        this.sideAdMarginTop = Math.max(window.pageYOffset - sideColumnHeight, 20)
    }

}
</script>